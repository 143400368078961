<template>
  <v-row>
    <v-col cols="4" lg="3">
      <v-switch :label="$t('active')" v-model="obPaymentTerm.active"></v-switch>
    </v-col>

    <v-col cols="4" lg="3">
      <v-switch
        :label="$t('paymentterm.cash')"
        :hint="$t('paymentterm.cash.hint')"
        v-model="obPaymentTerm.cash"
        persistent-hint
      ></v-switch>
    </v-col>

    <v-col v-if="!obPaymentTerm.cash" cols="4" lg="3">
      <v-switch
        :label="$t('can.modify.due.date')"
        v-model="obPaymentTerm.can_modify_date"
      ></v-switch>
    </v-col>

    <v-col cols="4" lg="3" align-self="end" class="text-right">
      <payment-terms-template-dialog
        v-if="!obPaymentTerm.cash && obPaymentTerm.isNew()"
        @change="onSelectTemplate"
      />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <form-field-text v-model="obPaymentTerm.name" required />
    </v-col>

    <v-col cols="12" md="6" lg="4">
      <code-field-text
        v-model="obPaymentTerm.code"
        label="code"
        item-key="paymentterm"
        :show-code-button="!obPaymentTerm.code"
        required
      />
    </v-col>

    <template v-if="!obPaymentTerm.cash">
      <!-- <v-col cols="12" md="6" lg="4">
        <ValidationProvider
          :name="$t('paymentterm.type')"
          rules="required"
          vid="paymentterm.type"
          slim
        >
          <v-radio-group
            v-model="obPaymentTerm.type"
            :hint="$t('paymentterm.type.hint')"
            :label="$t('paymentterm.type')"
            persistent-hint
            row
          >
            <v-radio :label="$t('paymentterm.fixed')" value="fixed"></v-radio>
            <v-radio
              :label="$t('paymentterm.infinite')"
              value="infinite"
            ></v-radio>
          </v-radio-group>
        </ValidationProvider>
      </v-col> -->

      <v-col cols="12" md="6" lg="4">
        <v-select
          v-model="obPaymentTerm.frequency"
          :label="$t('paymentterm.frequency')"
          :hint="$t('paymentterm.frequency.hint')"
          :items="headers"
          persistent-hint
          outlined
          dense
        ></v-select>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <form-field-text
          v-model="obPaymentTerm.frequency_interval"
          input-type="number"
          label="paymentterm.frequency.interval"
          :hint="$t('paymentterm.frequency.interval.hint')"
          persistent-hint
        />
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <form-field-text
          v-model="obPaymentTerm.cycles"
          input-type="number"
          label="paymentterm.cycles"
          :hint="$t('paymentterm.cycles.hint')"
          :disabled="obPaymentTerm.type == 'infinite'"
          persistent-hint
        />
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <form-field-text
          v-model="obPaymentTerm.delay"
          input-type="number"
          label="paymentterm.delay"
          :hint="$t('paymentterm.delay.hint')"
          persistent-hint
        />
      </v-col>
    </template>

    <v-col cols="12">
      <v-textarea
        :label="$t('addenda')"
        outlined
        v-model="obPaymentTerm.description"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
      lg="4"
      offset-md="3"
      offset-lg="4"
      v-if="!!sDelay && !!sFrecuency"
    >
      <sheet dense>
        <v-subheader v-text="$t('paymentterm.delay')" />
        <v-row>
          <v-col cols="6">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-text="sInitDate" />
                  <v-list-item-subtitle v-text="$t('date.current')" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-text="sSetDate" />
                  <v-list-item-subtitle v-text="$t('date.final')" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </sheet>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins, VModel } from "vue-property-decorator";
import { PaymentTerm } from "@planetadeleste/vue-mc-gw";
import { DataTableHeader } from "vuetify";
import DataTableMixin from "@/mixins/DataTableMixin";

import PaymentTermsTemplateDialog from "@/modules/paymentterms/components/PaymentTermsTemplateDialog.vue";
import { PaymentTermData } from "@planetadeleste/vue-mc-gw/src/types";
import dayjs from "dayjs";
import { number } from "mathjs";
import CodeFieldText from "@/components/form/fields/CodeFieldText.vue";

@Component({
  components: { CodeFieldText, PaymentTermsTemplateDialog },
})
export default class PaymenttermsSettings extends Mixins(DataTableMixin) {
  @VModel({ type: Object, default: () => new PaymentTerm() })
  obPaymentTerm!: PaymentTerm;
  frequencyItems: DataTableHeader[] = [
    { text: "day", value: "day" },
    { text: "week", value: "week" },
    { text: "month", value: "month" },
    { text: "year", value: "year" },
  ];

  mounted() {
    this.$nextTick(() => {
      this.setDTHeaders(this.frequencyItems);
    });
  }

  onSelectTemplate(obItem: Partial<PaymentTermData>) {
    this.obPaymentTerm.set(this.$_.omit(obItem, ["description"]));
  }

  get sDelay() {
    return this.obPaymentTerm ? number(this.obPaymentTerm.get("delay")) : null;
  }

  get sFrecuency() {
    return this.obPaymentTerm ? this.obPaymentTerm.get("frequency") : null;
  }

  get obInitDate() {
    return dayjs();
  }

  get sInitDate() {
    return this.obInitDate.format("YYYY-MM-DD");
  }

  get obSetDate() {
    const obDate = this.obInitDate.clone();

    if (this.sDelay && this.sFrecuency) {
      return obDate.add(this.sDelay, this.sFrecuency);
    }

    return obDate;
  }

  get sSetDate() {
    return this.obSetDate.format("YYYY-MM-DD");
  }
}
</script>
